<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
            </div>
            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+5543999105610"><i class="bx bxs-phone-call"></i> +55 (43) 9 9910 5610</a></li>
                        <li><a href="mailto:luidigastech@gmail.com"><i class="bx bxs-envelope"></i> luidigastech@gmail.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <!-- <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo2.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home </a>
                        </li>
                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Home</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Serviços</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Desenvolvimento softwhare personalizado</a></li>

                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Manutenção e suporte TI</a></li>

                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Desenvolvimento de Sites</a></li>

                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Criação de Logos</a></li>
                                
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sobre</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Soluções</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Soluções</a></li>
                            </ul>
                        </li>

                        

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Detalhes</a></li>
                            </ul>
                        </li> -->

                        <!--
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Clientes</a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Equipe</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Preços</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Galeria</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Entrar</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Termos e Condições</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                            
                        </li>
                        -->

                        <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Clientes</a></li>

                        <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Time</a></li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contato </a></li>
                        
                        <!--
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Nossos Projetos</a>
    
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/nossos-projetos" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Calculadora PJ e CLT</a></li>
    
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Preços</a></li>
                            </ul>
                        </li>
                        -->
                    </ul>  

                </div>
                <div class="nav-right">
                    <!-- <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form> -->
                </div>
                <div class="nav-btn">
                    <!-- <a routerLink="/solutions" class="box-btn">Get Started</a> -->
                </div>
            </nav>
        </div>
    </div>
</div>
