<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Time</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Time</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Conheça Nossa Equipe: Impulsionando Inovação e Excelência Tecnológica</h2>
            <p>Na Code Express, nossa equipe é o coração pulsante por trás de cada projeto, impulsionando a inovação e a excelência tecnológica em tudo o que fazemos. Com uma mistura única de talento, paixão e experiência, estamos aqui para superar expectativas e entregar resultados extraordinários.</p>
            <p>Cada membro da nossa equipe traz consigo um conjunto diversificado de habilidades e conhecimentos especializados, desde desenvolvedores de software altamente qualificados até designers criativos e especialistas em suporte técnico. O que nos une é o compromisso comum com a excelência e o desejo de criar soluções que realmente façam a diferença.</p>
            <p>Nosso ambiente de trabalho é caracterizado por uma cultura de colaboração, onde ideias são compartilhadas livremente e a inovação é incentivada. Estamos sempre buscando novas maneiras de superar desafios e encontrar soluções criativas para os problemas mais complexos.</p>
            <p>Além disso, estamos constantemente investindo no desenvolvimento profissional da nossa equipe, garantindo que estejamos sempre atualizados com as últimas tendências e tecnologias. Isso nos permite oferecer aos nossos clientes as soluções mais avançadas e eficazes disponíveis no mercado.</p>  
            <p>Na Code Express, acreditamos que o sucesso de um projeto está diretamente ligado à qualidade da equipe por trás dele. Estamos orgulhosos de contar com uma equipe de elite que está sempre pronta para superar desafios e elevar o padrão em tudo o que fazemos.</p> 
            <p>Junte-se a nós e descubra o que torna a equipe da Code Express tão especial. Estamos prontos para transformar sua visão em realidade, com dedicação, paixão e expertise incomparáveis.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/t1.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Luis Eduardo de Oliveira Martins</h3>
                        <p>Founder</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/danilo.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Danilo de Oliveira Francisquete</h3>
                        <p>Project Developer</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/mariana.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Mariana Bernardes</h3>
                        <p>QA</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <!--
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g5.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Leo Doe</h3>
                        <p>Project Manager</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g4.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Jhon Doe</h3>
                        <p>Marketing Support</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/g6.jpg" alt="team">
                    </div>

                    <div class="team-content">
                        <h3>Brian Cox</h3>
                        <p>Agent Management</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
-->
        </div>
    </div>
</section>
