<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sobre</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Sobre</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Nossa Empresa</h2>
                        <span>Serviço Inovador de Ajuda em Todo o Mundo</span>
                        <p>Bem-vindo à Code Express, sua parceira de confiança no mundo dinâmico da Tecnologia da Informação. Estamos comprometidos em levar sua visão além dos limites, oferecendo serviços abrangentes que abraçam a excelência em cada etapa do caminho.</p>
                        <p>Teste de Controle de Qualidade:
                         Na Code Express, a qualidade é a espinha dorsal de tudo o que fazemos. Nossos especialistas em Teste de Controle de Qualidade garantem que cada linha de código, cada funcionalidade e cada aplicativo seja submetido a rigorosos testes para garantir desempenho impecável. Sua confiança na qualidade de nossos produtos é nossa prioridade.</p>
                        <p> Desenvolvimento: 
                        Do conceito à implementação, a Code Express é sua parceira de desenvolvimento personalizado. Nossa equipe altamente qualificada está pronta para transformar suas ideias em soluções tangíveis. Seja no desenvolvimento de software inovador, aplicativos móveis ou soluções tecnológicas avançadas, nossa abordagem colaborativa impulsiona o sucesso sustentável de sua empresa.</p>
                        <p>Design Visual:
                            A estética encontra a funcionalidade em nossos serviços de Design Visual. Nossa equipe de designers talentosos é apaixonada por criar identidades visuais marcantes e interfaces intuitivas que elevam a experiência do usuário. Na Code Express, acreditamos que a primeira impressão é fundamental, e nosso design visual excepcional é a chave para destacar sua marca no cenário digital.</p>
                        <p>Expressando ideias, codificando o futuro: Code Express. Descubra como a Code Express pode impulsionar sua presença digital, proporcionando soluções de qualidade, desenvolvimento inovador e design visual impactante. Juntos, transformamos ideias em realidade digital.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div >
                <div class="technology-content">
                    <h2>Conheça Nossa Equipe: Impulsionando Inovação e Excelência Tecnológica</h2>
                    <p>Na Code Express, nossa equipe é o coração pulsante por trás de cada projeto, impulsionando a inovação e a excelência tecnológica em tudo o que fazemos. Com uma mistura única de talento, paixão e experiência, estamos aqui para superar expectativas e entregar resultados extraordinários.
                        Cada membro da nossa equipe traz consigo um conjunto diversificado de habilidades e conhecimentos especializados, desde desenvolvedores de software altamente qualificados até designers criativos e especialistas em suporte técnico. O que nos une é o compromisso comum com a excelência e o desejo de criar soluções que realmente façam a diferença.
                        </p>
                   
                   

                    <a routerLink="/team" class="box-btn">Ver Mais</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<!--
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>
-->

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        
                        <h2>
                            Características
                        </h2>
                        <span>As 10 principais características sobre nossa empresa:</span>
                    </div>
                    <ul>
                        <li>
                            <i class="flaticon-correct"></i> Transparência e comunicação eficas durante todo o processo de desenvolvimento
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Utilização de tecnologias de ponta e melhores práticas de desenvolvimento
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Excelência no suporte pós-lançamento e manutenção do sofware
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Agilidade e flexibilidade para atender ás demandas do clientes

                        <li>
                            <i class="flaticon-correct"></i> Adaptabilidade ás mudanças do mercado e tecnológicas
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Foco na qualidade e eficiência do software desenvolvido
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Compromisso com a segurança e proteção dos dados
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Abordagem centrada o cliente e suas necessidades
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Inovação constante em soluções tecnolóigicas
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Equipe altamente qualificada e experiente
                        </li>

                    </ul>
                    <a routerLink="/contact" class="box-btn"
                        >Vamos conversar!</a
                    >
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Depoimentos</span>
            <h2>O que Nossos Clientes Dizem</h2>
         
        </div>

        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/primeburgue.jpg" alt="img">
                        <p>A Code Express de Tecnologia superou todas as minhas expectativas! Equipe competente, 
                            atendimento personalizado e resultados surpreendentes. Parabéns pelo excelente trabalho!</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/italianpizza.jpg" alt="img">
                        <p>Estou muito impressionado com o trabalho da Code Express de Tecnologia
                            Profissionalismo excepcional, entrega rápida e soluções de alta qualidade. 
                            Recomendo totalmente</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/julianamodas.jpg" alt="img">
                        <p>
                            Confio na Code Express de Tecnologia para todos os meus projetos. Profissionalismo exemplar,
                             compromisso com a excelência e resultados que superam as expectativas. Simplesmente fantástico</p>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/acai.jpg" alt="img">
                        <p> A Code Express de Tecnologia é simplesmente incrível! Sempre entregam projetos 
                            impecáveis, com inovação e eficiência. Estou extremamente satisfeito com os serviços prestados.
                             Recomendo a todos!</p>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        
        <a routerLink="/solutions-details" class="box-btn"
        >Ver Mais!</a
    >
    </div>
    
</section>
