<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Code Express</h1>
                            <p>
                                Expressando Ideias, Codificando o Futuro: Code
                                Express.
                            </p>
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn"
                                    >Contate-nos</a
                                >
                                <a
                                    routerLink="/about"
                                    class="box-btn border-btn"
                                    >Saber mais</a
                                >
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img
                                src="assets/images/home-bg-1-img.png"
                                alt="banner-img"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1">
            <img src="assets/images/shape/1.png" alt="shape" />
        </div>
        <div class="shape2">
            <img src="assets/images/shape/2.png" alt="shape" />
        </div>
        <div class="shape3">
            <img src="assets/images/shape/3.png" alt="shape" />
        </div>
        <div class="shape4">
            <img src="assets/images/shape/4.png" alt="shape" />
        </div>
        <div class="shape5">
            <img src="assets/images/shape/5.png" alt="shape" />
        </div>
        <div class="shape6">
            <img src="assets/images/shape/6.png" alt="shape" />
        </div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>
                Serviços
            </h2>
            <span>Code Express fornece todos os tipos de soluções tecnológicas</span>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Desenvolvimento de sofware personalizado</h3>
                        <p>
                            Em um mundo digitalmente interconectado, a presença online
                            é vital para o sucesso de qualquer. 
                        </p>
                        <a routerLink="/solutions" class="line-bnt"
                            >Ler Mais</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Manutenção e suporte TI</h3>
                        <p>
                            Na era digital em que vivemos, a tecnologia desempenha
                            um papel fundamental no funcionamento de qualquer 
                           
                        </p>
                        <a routerLink="/solutions" class="line-bnt"
                            >Ler Mais</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Desenvolvimento de Sites</h3>
                        <p>
                            Na era digital em que vivemos, um site é a porta de 
                            entrada para a sua empresa no mundo online. É a primeira 
                           
                        </p>
                        <a routerLink="/solutions" class="line-bnt"
                            >Ler Mais</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Criação de logos
</h3>
                        <p>
                            Sua logo é mais do que apenas uma imagem; é a representação 
                            visual da identidade e dos valores da sua empresa. É a primeira 
                            coisa  
                           
                        </p>
                        <a routerLink="/solutions" class="line-bnt"
                            >Ler Mais</a
                        >
                    </div>
                </div>
            </div> 

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Cíber Segurança</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Atque vel sit maxime assumenda. maiores,
                            magnam
                        </p>
                        <a routerLink="/solutions-details" class="line-bnt"
                            >Read More</a
                        >
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Wireless Connectivity</h3>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Atque vel sit maxime assumenda. maiores,
                            magnam
                        </p>
                        <a routerLink="/solutions-details" class="line-bnt"
                            >Read More</a
                        >
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <h2>Nossa Empresa</h2>
                        <span>Serviço Inovador de Ajuda em Todo o Mundo.</span>
                        <p>
                            Bem-vindo à Code Express, sua parceira de confiança
                            no mundo dinâmico da Tecnologia da Informação.
                        </p>
                        
                        <p>
                            Estamos comprometidos em levar sua visão além dos
                            limites, oferecendo serviços abrangentes que abraçam
                            a excelência em cada etapa do caminho.
                        </p>
                    </div>

                    <a routerLink="/about" class="box-btn">Ler Mais</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img
                        src="assets/images/company/company-img.jpg"
                        alt="company"
                    />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            
            <h2>
                Escolher Code Express
            </h2>
            <span>Conquistamos a confiança das pessoas através do nosso excelente
                serviço</span>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <div
                            class="faq-item"
                            *ngFor="let item of accordionItems; let i = index"
                        >
                            <div
                                class="faq-header"
                                (click)="toggleAccordionItem(item)"
                            >
                                {{ item.title }}
                                <i
                                    class="bx"
                                    [ngClass]="{
                                        'bx-plus': !item.open,
                                        'bx-x': item.open
                                    }"
                                ></i>
                            </div>
                            <div class="faq-content" [hidden]="!item.open">
                                <p>{{ item.content }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Processo de Trabalho</h2>
            <span>Somos populares por causa da nossa maneira de trabalhar</span>
        
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span
                            ><img
                                src="assets/images/process/next.png"
                                alt="shape"
                        /></span>
                    </div>

                    <div class="content">
                        <h3>Pesquisa de Produto</h3>
                        <p>Mais do que uma etapa no processo de desenvolvimento; é a chave para abrir portas para a inovação e para moldar o futuro digital. Com um compromisso sólido com a pesquisa e desenvolvimento, estamos empenhados em criar produtos que não apenas atendam às necessidades presentes, mas também antecipem e superem as expectativas futuras.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"
                            ><img
                                src="assets/images/process/next.png"
                                alt="shape"
                        /></span>
                    </div>

                    <div class="content">
                        <h3>Teste de Usuário</h3>
                        <p>Reconhecemos que a qualidade de um produto vai além da funcionalidade técnica; reside na experiência do usuário. É por isso que incorporamos o "Teste de Usuário" como uma etapa essencial em nosso processo de desenvolvimento de novos produtos, garantindo que cada solução não apenas atenda, mas exceda as expectativas do usuário.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span
                            ><img
                                src="assets/images/process/next.png"
                                alt="shape"
                        /></span>
                    </div>

                    <div class="content">
                        <h3>Desenvolvimento</h3>
                        <p>Vai além de linhas de código; é uma jornada de transformação digital. Nosso compromisso é transformar suas visões em soluções tangíveis, impulsionando sua presença online e alavancando seu sucesso.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Entrega do Produto</h3>
                        <p>A entrega do produto não é apenas um marco; é o culminar de nossa dedicação à excelência, inovação e satisfação do cliente. Nossa abordagem holística assegura que a entrega não seja apenas um momento, mas sim um processo cuidadosamente gerenciado que reflete nosso compromisso com o sucesso do cliente.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Estudos de Caso</span>
            <h2>Dê uma olhada em nossa vitrine de trabalho</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse.
            </p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab1' }"
                >
                    <span (click)="switchTab($event, 'tab1')"> All </span>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab2' }"
                >
                    <span (click)="switchTab($event, 'tab2')"> Design </span>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab3' }"
                >
                    <span (click)="switchTab($event, 'tab3')">
                        Desenvolvimento
                    </span>
                </li>
                <li
                    class="nav-tab"
                    [ngClass]="{ active: currentTab === 'tab4' }"
                >
                    <span (click)="switchTab($event, 'tab4')">
                        Cíber Segurança
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                <div class="pane" id="tab1" *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c1.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Joe’s Company Software Desenvolvimento
                                            Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c2.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Ride Share App UX Studies &
                                            Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c3.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Restaurant Management & Web
                                            Developing
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c4.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            IT Software Company Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c5.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Parking Management & Web Developing
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c6.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Temperature App UX Studies &
                                            Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c4.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            IT Software Company Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c5.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Parking Management & Web Developing
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c6.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Temperature App UX Studies &
                                            Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c2.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Ride Share App UX Studies &
                                            Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c3.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Restaurant Management & Web
                                            Developing
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c4.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            IT Software Company Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c6.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Temperature App UX Studies &
                                            Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c4.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            IT Software Company Desenvolvimento Case
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"
                                        ><img
                                            src="assets/images/case/c5.jpg"
                                            alt="case"
                                    /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details"
                                        ><h3>
                                            Parking Management & Web Developing
                                        </h3></a
                                    >
                                    <p>
                                        Lorem ipsum dolor sit amet, coloni is to
                                        nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.
                                    </p>
                                    <a
                                        routerLink="/case-studies-details"
                                        class="line-bnt"
                                        >View Project Detalhes</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div>
    </div>
</section> -->

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        
                        <h2>
                            Características
                        </h2>
                        <span>As 10 principais características sobre nossa empresa:</span>
                    </div>
                    <ul>
                        <li>
                            <i class="flaticon-correct"></i> Transparência e comunicação eficas durante todo o processo de desenvolvimento
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Utilização de tecnologias de ponta e melhores práticas de desenvolvimento
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Excelência no suporte pós-lançamento e manutenção do sofware
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Agilidade e flexibilidade para atender ás demandas do clientes

                        <li>
                            <i class="flaticon-correct"></i> Adaptabilidade ás mudanças do mercado e tecnológicas
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Foco na qualidade e eficiência do software desenvolvido
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Compromisso com a segurança e proteção dos dados
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Abordagem centrada o cliente e suas necessidades
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Inovação constante em soluções tecnolóigicas
                        </li>

                        <li>
                            <i class="flaticon-correct"></i> Equipe altamente qualificada e experiente
                        </li>

                    </ul>
                    <a routerLink="/contact" class="box-btn"
                        >Vamos conversar!</a
                    >
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Equipe Members</span>
            <h2>Pessoas Que Estão por Trás das Conquistas</h2>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Luiz Eduardo</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Mariana Bernardes</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t1.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Luiz Eduardo</h3>
                            <p>Full Stack Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Mariana Bernardes</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />

                            <ul class="social">
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-linkedin"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-facebook"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxl-twitter"></i
                                    ></a>
                                </li>
                                <li>
                                    <a href="#" target="_blank"
                                        ><i class="bx bxs-envelope"></i
                                    ></a>
                                </li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Depoimentos</span>
            <h2>O que Nossos Clientes Dizem</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                facilis vel consequatur tempora atque blanditiis exercitationem
                incidunt, alias corporis quam assumenda dicta.
            </p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Lorem Ipsum is simply dummy
                            text of the printing Quis suspendisse typesetting
                            ipsum dolor sit amet,
                        </p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img" />
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Lorem Ipsum is simply dummy
                            text of the printing Quis suspendisse typesetting
                            ipsum dolor sit amet,
                        </p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->

<!-- <section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                facilis vel consequatur tempora atque blanditiis exercitationem
                incidunt, alias corporis quam assumenda dicta.
            </p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"
                            ><img src="assets/images/blog/1.jpg" alt="blog"
                        /></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"
                            ><h3>Joe’s Company Software Desenvolvimento Case</h3></a
                        >
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptas in fugit minima modi perspiciatis nam
                            aspernatur porro
                        </p>
                        <a routerLink="/blog-details" class="line-bnt"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"
                            ><img src="assets/images/blog/5.jpg" alt="blog"
                        /></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"
                            ><h3>
                                Temperature App UX Studies & Desenvolvimento Case
                            </h3></a
                        >
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptas in fugit minima modi perspiciatis nam
                            aspernatur porro
                        </p>
                        <a routerLink="/blog-details" class="line-bnt"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"
                            ><img src="assets/images/blog/3.jpg" alt="blog"
                        /></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2023</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details"
                            ><h3>IT Software Company Desenvolvimento Case</h3></a
                        >
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Voluptas in fugit minima modi perspiciatis nam
                            aspernatur porro
                        </p>
                        <a routerLink="/blog-details" class="line-bnt"
                            >Read More</a
                        >
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>
                We Have More Usefull Blogs For You.
                <a routerLink="/blog">View More</a>
            </p>
        </div>
    </div>
</section> -->
<!--
<section class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contato Us</span>
            <h2>Deixe-nos saber sobre sua ideia de projeto!</h2>
            <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. A
                facilis vel consequatur tempora atque blanditiis exercitationem
                incidunt, alias corporis quam assumenda dicta.
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact" />
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        id="name"
                                        class="form-control"
                                        required
                                        placeholder="Your Name"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        class="form-control"
                                        required
                                        placeholder="Your Email"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="phone_number"
                                        id="phone_number"
                                        required
                                        class="form-control"
                                        placeholder="Your Phone"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input
                                        type="text"
                                        name="msg_subject"
                                        id="msg_subject"
                                        class="form-control"
                                        required
                                        placeholder="Subject"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        id="message"
                                        cols="30"
                                        rows="5"
                                        required
                                        placeholder="Your Message"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button
                                    type="submit"
                                    class="default-btn page-btn box-btn"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->
