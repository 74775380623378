<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Preços</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Preços</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What We Offer</span>
            <h2>Our Preços Plan For You</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Basic</h3>
                        <p>Build A Website</p>
                    </div>
                    <span>$29<sub>/M</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> 10GB Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> 50GB Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> 10 Free Sub-Domains</li>
                        <li><i class="bx bx-badge-check"></i> 10GB Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> 50GB Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> 10 Free Sub-Domains</li>
                    </ul>

                    <a class="box-btn" routerLink="/">Get Started</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Standard</h3>
                        <p>Build A Website</p>
                    </div>
                    <span>$59 <sub>/M</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> 80GB Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> 90GB Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> 10 Free Sub-Domains</li>
                        <li><i class="bx bx-badge-check"></i> 70GB Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> 50GB Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Sub-Domains</li>
                    </ul>

                    <a class="box-btn" routerLink="/">Get Started</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>Premium</h3>
                        <p>Build A Website</p>
                    </div>
                    <span>$79 <sub>/M</sub></span>

                    <ul>
                        <li><i class="bx bx-badge-check"></i> Unlimited Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Sub-Domains</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Storage Space</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Bandwidth</li>
                        <li><i class="bx bx-badge-check"></i> Unlimited Domains</li>
                    </ul>

                    <a class="box-btn" routerLink="/">Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>
