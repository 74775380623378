<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contato</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Contato</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p>hello@code-express.com</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p>28/A Street, New York City, USA</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p>+1 (321) 984 754</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <h2>Deixe-nos saber sobre sua ideia de projeto!</h2>
            <p>Na Code Express, estamos sempre prontos para ouvir sobre suas ideias inovadoras e empolgantes para projetos de tecnologia. 
                Seja qual for a sua visão - desde o desenvolvimento de um novo aplicativo até a revitalização do seu site corporativo - estamos
                 aqui para transformar suas ideias em realidade digital.Nossa equipe especializada está ansiosa para colaborar com você, entender
                 suas necessidades e objetivos, e trabalhar em conjunto para criar uma solução personalizada que atenda às suas expectativas e supere suas metas.
                Queremos ouvir tudo sobre sua ideia de projeto - desde os detalhes específicos até as grandes aspirações. Acreditamos que cada projeto começa com 
                uma conversa, e estamos comprometidos em ouvir atentamente suas ideias, responder às suas perguntas e oferecer insights valiosos para ajudá-lo a
                 transformar sua visão em uma realidade tangível.Então, não espere mais! Deixe-nos saber sobre sua ideia de projeto hoje mesmo. Estamos aqui para 
                ajudar a transformar sua visão em sucesso digital.
                
                Entre em contato conosco para começar a conversa. Mal podemos esperar para ouvir o que você tem em mente!</p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required placeholder="Seu Nome" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required placeholder="Seu Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Seu Telefone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Assunto" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Sua Mensagem"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Enviar</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>
