<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <!--
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo2.png" alt="logo" /></a>
                    </div>
                    -->
                 
                   <!--
                    <div class="logo">
                        <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                    </div>
                    -->
                    
                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin' ></i></a></li>
                        
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                        
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content ml-15">
                    <h3>Nosso Serviço</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/solutions">Desenvolvimento de sofware personalizado</a></li>
                        <li><a routerLink="/solutions"> Manutenção e suporte de TI</a></li>
                        <li><a routerLink="/solutions">Desenvolvimento de Sites</a></li>
                        <li><a routerLink="/solutions">Criação de Logo</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content">
                    <h3>Links Rápidos</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/solutions">Serviços</a></li>
                        <li><a routerLink="/about">Sobre</a></li>
                        <li><a routerLink="/">Soluções</a></li>
                        <li><a routerLink="/solutions-details">Clientes</a></li>
                        <li><a routerLink="/team">Time</a></li>
                        <li><a routerLink="/contact">Contato</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contato</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+5543999105610"><i class="bx bx-mobile-alt"></i> +55 (43) 9 9910 5610</a></li>
                        <li><a href="mailto:luidigastech@gmail.com"><i class="bx bxs-envelope"></i> luidigastech@gmail.com</a></li>
                        <li><a href="tel:+5543998131155"><i class="bx bx-mobile-alt"></i> +55 (43) 9 9813 1155</a></li>
                        <li><a href="mailto:danilofrancisquete@hotmail.com"><i class="bx bxs-envelope"></i> danilofrancisquete@hotmail.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/solutions">Serviços</a></li>
                        <li><a routerLink="/about">Sobre</a></li>
                        <li><a routerLink="/">Soluções</a></li>
                        <li><a routerLink="/solutions-details">Clientes</a></li>
                        <li><a routerLink="/team">Time</a></li>
                        <li><a routerLink="/contact">Contato</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
